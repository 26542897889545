import React, { useState, useEffect } from "react";

import M_company from "./M_company";
import D_company from "./D_company";

const MainCompany = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1300);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1300);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div>{isMobile ? <M_company /> : <D_company />}</div>;
};

export default MainCompany;
